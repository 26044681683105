import * as types from 'actions/actionTypes'
import initialState from 'reducers/initialState'

export const autoPromotionRule = (state = initialState.autoPromotionRules, action) => {
  switch (action.type) {
    case types.CREATE_AUTO_PROMOTION_RULE_SUCCESS:
      return { ...state, autoPromotionRules: action.payload }

    case types.CREATE_AUTO_PROMOTION_RULE_FAILURE:
      return state

    case types.FETCH_AUTO_PROMOTION_RULE_SUCCESS:
      return { ...state, autoPromotionRules: action.payload }

    case types.FETCH_AUTO_PROMOTION_RULE_FAILURE:
      return state

    case types.DELETE_AUTO_PROMOTION_RULE_SUCCESS:
      return {
        ...state,
        autoPromotionRules: state.autoPromotionRules.filter((rule) => rule.id !== action.payload.id)
      }

    case types.DELETE_AUTO_PROMOTION_RULE_FAILURE:
      return state

    default:
      return state
  }
}
