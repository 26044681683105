import * as types from '../actions/actionTypes'

import initialState from './initialState'

export const rank = (state = initialState.ranks, action) => {
  switch (action.type) {
    case types.FETCH_RANK_SUCCESS:
      return { ...state, ranks: action.payload }
    case types.FETCH_RANK_FAILURE:
      return state
    default:
      return state
  }
}
