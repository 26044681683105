import moment from 'moment'

import Anniversay from 'assets/img/anniversary_poster.png'
import Birthday from 'assets/img/birthday_poster.png'
import Promotion from 'assets/img/promotion_poster.png'
import Welcome from 'assets/img/welcome_poster.png'

import { checkArrayPresence, sortValue } from './applicationHelper'
import { getCurrentDate } from './datesHelper'

export const sendEmailListOptions = [
  { label: 'Assigned User', value: '1' },
  { label: 'CC List', value: '2' }
]

export const probationReviewerOptions = [
  { label: 'Reporting To', value: 'reporting_to' },
  { label: 'Team Lead', value: 'team_lead' },
  { label: 'Manager', value: 'manager' }
]

export const reviewOptions = [
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Biannual', value: 'biannual' },
  { label: 'Annual', value: 'annual' }
]

export const reviewTriggerModeOptions = [
  { label: 'Pre Days', value: 'pre_days' },
  { label: 'Post Days', value: 'post_days' }
]

export const sourceTypeOptions = [
  { label: 'Maple', value: 'maple' },
  { label: 'Maple & Workflows', value: 'maple_and_workflow' }
]

export const reviewOccuranceModeOptions = [
  { label: 'Calendar Date', value: 'calendar_date' },
  { label: 'Joining Date', value: 'joining_date' }
]
export const LeavePolicyTypeOptions = [
  { label: 'Calendar year', value: 'calender_wise' },
  { label: 'Employee joining year', value: 'employee_wise' }
]
export const userLimitNotificationDates = [
  { label: 'Daily', value: '1' },
  { label: 'Every 3rd Day', value: '3' },
  { label: 'Every 5th Day', value: '5' },
  { label: 'Every 7th Day', value: '7' },
  { label: 'Every 10th Day', value: '10' },
  { label: 'Every 15th Day', value: '15' }
]
export const claimTypeFileAttachmentBtns = [
  { id: 0, label: 'Image', value: 'image' },
  { id: 1, label: 'Document', value: 'file' }
]

export const claimTypeClaimsDispersionBtns = [
  { id: 0, label: 'Out of payroll', value: 'out_of_payroll' },
  { id: 1, label: 'Dispatch with payroll', value: 'with_payroll' }
]

export const claimTypePayrollAllowanceBindingTypeBtns = [
  { id: 0, label: 'None', value: 'none' },
  { id: 1, label: 'All', value: 'all' }
]

export const getTabPolicy = (tab) => {
  if (tab === 'project') return 'projectPolicies'
  if (tab === 'dnarsSettings') return 'dnarsPolicies'
}

export const getPreOnBoardSettingTabs = (hasAccess, canViewOnly) => {
  let tabs = []
  if (hasAccess) tabs.push({ value: 'manage-invite', label: 'Manage Invite' })
  if (hasAccess || canViewOnly) tabs.push({ value: 'manage-pages', label: 'Manage Pages' })
  return tabs
}

export const checkPoliciesLength = (policies, type) => policies.filter((policy) => policy.model === type).length === 0

export const filterPolicy = (policies, name) => policies.find((policy) => policy.name === name)

export const findRecord = (collection, value) => collection.find((item) => item.id === value)

export const filterRecord = (collection, property, value) =>
  collection.filter((record) => !!record).find((record) => record[property] === value)

export const formatForSelect = (collection) => collection.map((data) => ({ value: data, label: data }))

export const selectedValues = (collection) => collection.map((data) => data.value)

export const policyId = (policies, name) =>
  policies.length !== 0 ? policies.find((policy) => policy.name === name).id : null

export const collectionIntoSelectOptions = (collection, value, label, isLabelWithId = false, sortWithLabel = false) => {
  const sorted =
    sortWithLabel && collection?.length > 1 ? collection.sort((a, b) => a[label]?.localeCompare(b[label])) : collection

  return sorted?.map((record) => ({
    value: record[value],
    label: isLabelWithId ? `${record[label]} (ID-${record[value]})` : record[label]
  }))
}

export const ranksIntoSelectOptions = (collection, value, label) =>
  collection?.map((record) => ({
    value: record[value],
    label: record[label]
  }))

export const levelsIntoSelectOptions = (titles, selectedLevels) =>
  titles.reduce((newTitles, current) => {
    if (selectedLevels?.includes(current.id) || !newTitles[current.level]) {
      newTitles[current.level] = {
        value: current.id,
        label: `Level-${current.level}`
      }
    }
    return newTitles
  }, [])

export const attributesIntoSelectOptions = (collection) =>
  collection?.map((record) => ({ value: record, label: record.humanize().titleize() }))

export const collectionIntoSelectOptionsWithImages = (collection, value, name, avatar) =>
  sortValue(
    collection
      .filter((record) => !!record)
      .map((record) => ({ value: record[value], label: record[name], avatar: record[avatar] }))
  )

export const formatSelectValue = (collection) =>
  collection.map((item) => ({ value: item.label.toLowerCase(), label: item.label }))

export const selectOptionsWithImages = (collection, dataObj, extraOptions, className) =>
  collection.map((record) => ({
    label: (
      <div className={className}>
        <img src={record[dataObj['imgSrc']]} alt={record[dataObj['labelSrc']]} />{' '}
        <div>{record[dataObj['labelSrc']]}</div>
      </div>
    ),
    value: record[dataObj['value']],
    [extraOptions['extraFieldLabel']]: record[extraOptions['extraFieldValue']]
  }))

export const getMemberValues = (category) => {
  let moderatorsLocationAttributes = []
  let staffLocationAttributes = []

  category.location_binded_moderators?.forEach((locationBindedModerator) => {
    const moderatorsAttributes = Array.isArray(locationBindedModerator.moderators_attributes)
      ? locationBindedModerator.moderators_attributes
      : [locationBindedModerator.moderators_attributes]
    moderatorsAttributes?.forEach((moderatorId) => {
      if (!locationBindedModerator.moderator_locations || locationBindedModerator.moderator_locations.length === 0) {
        moderatorId &&
          moderatorsLocationAttributes.push({
            user_id: moderatorId,
            location_id: null
          })
      } else {
        const locationAttributes = Array.isArray(locationBindedModerator.moderator_locations)
          ? locationBindedModerator.moderator_locations
          : [locationBindedModerator.moderator_locations]
        locationAttributes?.forEach((locationId) => {
          if (moderatorId && locationId) {
            moderatorsLocationAttributes.push({
              user_id: moderatorId,
              location_id: locationId
            })
          }
        })
      }
    })
  })

  category.location_binded_staff?.forEach((staff) => {
    const staffAttributes = Array.isArray(staff.staff_attributes) ? staff.staff_attributes : [staff.staff_attributes]
    staffAttributes?.forEach((staffId) => {
      if (!staff.staff_locations || staff.staff_locations?.length === 0) {
        staffId &&
          staffLocationAttributes.push({
            user_id: staffId,
            location_id: null
          })
      } else {
        const locationAttributes = Array.isArray(staff.staff_locations)
          ? staff.staff_locations
          : [staff.staff_locations]
        locationAttributes?.forEach((locationId) => {
          if (staffId && locationId) {
            staffLocationAttributes.push({
              user_id: staffId,
              location_id: locationId
            })
          }
        })
      }
    })
  })

  const memberValues = {
    moderators_location_attributes: moderatorsLocationAttributes,
    staff_location_attributes: staffLocationAttributes,
    moderator_location_binded: category.location_binded_moderators[0].moderator_locations_dependent,
    staff_location_binded: category.location_binded_staff[0].staff_location_dependent
  }

  return memberValues
}

export const setNewCategories = (oldCategory, newCategory) => {
  let updatedModeratorAttributes = []
  let updateTicketStaffAttributes = []
  const memberValues = getMemberValues(newCategory)

  oldCategory.raw_moderators.forEach((oldModerator) => {
    let moderatorDeleted = true
    memberValues.moderators_location_attributes.forEach((newModerator) => {
      if (newModerator.location_id === oldModerator.location_id && newModerator.user_id === oldModerator.user_id) {
        updatedModeratorAttributes.push(oldModerator)
        memberValues.moderators_location_attributes = memberValues.moderators_location_attributes.filter(
          (moderator) => moderator !== newModerator
        )
        moderatorDeleted = false
      } else if (
        newModerator.location_id !== oldModerator.location_id &&
        oldModerator.location_id === null &&
        newModerator.user_id === oldModerator.user_id
      ) {
        oldModerator['location_id'] = newModerator.location_id
        updatedModeratorAttributes.push(oldModerator)
        memberValues.moderators_location_attributes = memberValues.moderators_location_attributes.filter(
          (moderator) => moderator !== newModerator
        )
        moderatorDeleted = false
      }
    })
    if (moderatorDeleted) {
      oldModerator['_destroy'] = true
      updatedModeratorAttributes.push(oldModerator)
    }
  })
  updatedModeratorAttributes = updatedModeratorAttributes.concat(memberValues.moderators_location_attributes)

  oldCategory.raw_staff.forEach((oldStaff) => {
    let staffDeleted = true
    memberValues.staff_location_attributes.forEach((newStaff) => {
      if (newStaff.location_id === oldStaff.location_id && newStaff.user_id === oldStaff.user_id) {
        updateTicketStaffAttributes.push(oldStaff)
        memberValues.staff_location_attributes = memberValues.staff_location_attributes.filter(
          (staff) => staff !== newStaff
        )
        staffDeleted = false
      } else if (
        newStaff.location_id !== oldStaff.location_id &&
        oldStaff.location_id === null &&
        newStaff.user_id === oldStaff.user_id
      ) {
        oldStaff['location_id'] = newStaff.location_id
        updateTicketStaffAttributes.push(oldStaff)
        memberValues.staff_location_attributes = memberValues.staff_location_attributes.filter(
          (staff) => staff !== newStaff
        )
        staffDeleted = false
      }
    })
    if (staffDeleted) {
      oldStaff['_destroy'] = true
      updateTicketStaffAttributes.push(oldStaff)
    }
  })

  updateTicketStaffAttributes = updateTicketStaffAttributes.concat(memberValues.staff_location_attributes)

  const moderatorLocationBinded = memberValues.moderator_location_binded
  const staffLocationBinded = memberValues.staff_location_binded

  let ticketApprovalAttributes = [],
    ticketApprovers = []
  let oldApprover = {},
    oldUser = {}
  const oldAttributesData = oldCategory?.ticket_approval_timelines[0]?.ticket_approval_settings ?? []

  newCategory.ticket_approval_settings_attributes
    .filter((approvers) => approvers.location_approval_required)
    .map((approver) => {
      oldApprover = oldAttributesData.find(
        (app) => app.location?.id === approver.approvers_location_id || app.location === approver.approvers_location_id
      )
      if (oldApprover?.id) {
        ticketApprovers = []
        approver.ticket_approval_ids?.map((userId) => {
          if (approver.applicable_approvers.includes('dynamic_approvals_required')) {
            oldUser = oldApprover.approvals.find((user) => user.user_id === userId)
            return oldUser?.id
              ? ticketApprovers.push({ id: oldUser.id, user_id: userId })
              : ticketApprovers.push({ user_id: userId })
          }
        })
        oldApprover.approvals.map((oldUser) => {
          if (!ticketApprovers.find((selectedUser) => selectedUser.user_id === oldUser.user_id)) {
            ticketApprovers.push({ id: oldUser.id, _destroy: true })
          }
        })
        ticketApprovalAttributes.push({
          id: oldApprover.id,
          pm_approval_required: approver.applicable_approvers.includes('pm_approval_required'),
          lead_approval_required: approver.applicable_approvers.includes('lead_approval_required'),
          dynamic_approvals_required: approver.applicable_approvers.includes('dynamic_approvals_required'),
          location_id: approver.approvers_location_id,
          approvals_in_sequence: approver.approvals_in_sequence,
          ticket_approvals_attributes: ticketApprovers
        })
      } else {
        ticketApprovalAttributes.push({
          pm_approval_required: approver.applicable_approvers.includes('pm_approval_required'),
          lead_approval_required: approver.applicable_approvers.includes('lead_approval_required'),
          dynamic_approvals_required: approver.applicable_approvers.includes('dynamic_approvals_required'),
          approvals_in_sequence: approver.approvals_in_sequence,
          location_id: approver.approvers_location_id,
          ticket_approvals_attributes: approver.ticket_approval_ids?.map((userId) => ({
            user_id: userId
          }))
        })
      }
    })

  oldAttributesData.map((approverSetting) => {
    if (
      !ticketApprovalAttributes.find(
        (approver) =>
          approver.location_id === approverSetting.location || approver.location_id === approverSetting.location?.id
      )
    ) {
      ticketApprovalAttributes.push({
        id: approverSetting.id,
        _destroy: true,
        ticket_approvals_attributes: approverSetting.approvals.map((user) => ({ id: user.id, _destroy: true }))
      })
    }
  })

  const categories = {
    id: newCategory.id,
    name: newCategory.name,
    color: newCategory.color,
    days_required: newCategory.days_required,
    department_id: newCategory.department_id,
    day_preference: newCategory.day_preference,
    description: newCategory.description,
    approval_required: newCategory.approval_required,
    ticket_staff_attributes: updateTicketStaffAttributes,
    moderators_attributes: updatedModeratorAttributes,
    moderator_location_binded: Boolean(moderatorLocationBinded),
    staff_location_binded: Boolean(staffLocationBinded),
    ticket_approval_timelines_attributes: oldCategory.ticket_approval_timelines[0]?.id
      ? newCategory.approval_required
        ? [
            {
              id: oldCategory.ticket_approval_timelines[0].id,
              applicable_from: newCategory.applicable_from,
              approvals_location_binded: newCategory.approval_required ? newCategory.approvals_location_binded : false,
              ticket_approval_settings_attributes: ticketApprovalAttributes
            }
          ]
        : oldCategory.approval_required
          ? [
              {
                id: oldCategory.ticket_approval_timelines[0].id,
                applicable_from: oldCategory.ticket_approval_timelines[0].applicable_from,
                applicable_till: moment(getCurrentDate()).format('YYYY-MM-DD'),
                approvals_location_binded: oldCategory.approval_required
                  ? oldCategory.approvals_location_binded
                  : false,
                active: false
              }
            ]
          : []
      : newCategory.approval_required
        ? [
            {
              applicable_from: newCategory.applicable_from,
              approvals_location_binded: newCategory.approval_required ? newCategory.approvals_location_binded : false,
              ticket_approval_settings_attributes: ticketApprovalAttributes
            }
          ]
        : []
  }

  return categories
}

export const settingsTabs = [
  {
    value: '/settings',
    label: 'Password',
    href: '/settings'
  },
  {
    value: '/settings/notifications',
    label: 'Notification',
    href: '/settings/notifications'
  },
  {
    value: '/settings/assistant-permissions',
    label: 'Assistant Permissions',
    href: '/settings/assistant-permissions'
  }
]

export const isSettingsTabs = (path) =>
  ['/settings', '/settings/notifications', '/settings/assistant-permissions'].includes(path) || false

export const frequencyOptions = [
  { label: 'No limit', value: 'no_limit' },
  { label: 'N per day', value: 'per_day_limit' },
  { label: 'N per month', value: 'per_month_limit' },
  { label: 'N per year', value: 'per_year_limit' }
]

export const applicableOnOptions = [
  { label: 'Public Holidays', value: 'public_holidays' },
  { label: 'Weekends', value: 'weekends' }
]

export const subscriptionTypesOptions = [
  { label: 'Food', value: 'food' },
  { label: 'Transport Allowance', value: 'transport_allowance' },
  { label: 'Others', value: 'others' }
]

export const projectBindingOptions = [
  { label: 'Project Binded', value: 'project_binded' },
  { label: 'Thread Binded', value: 'thread_restricted' }
]

export const imageSizeText = () => (
  <small> Note: Size of image should not be more than Width 47px and Height 47px </small>
)

export const setMaritalStatusAttributes = (newValues, leaveType) => {
  let updatedStatusAttributes = []
  leaveType.marital_status_attributes?.forEach((status) => {
    if (!newValues.marital_status_attributes.includes(status.status)) {
      updatedStatusAttributes.push({ id: status.id, _destroy: true })
    }
  })
  let oldMaritalStatus = leaveType.marital_status_attributes.map((status) => status.status)
  newValues.marital_status_attributes?.forEach((status) => {
    if (!oldMaritalStatus.includes(status)) {
      updatedStatusAttributes.push({ status: status })
    }
  })
  return updatedStatusAttributes
}

export const setGenderAttributes = (newValues, leaveType) => {
  let updatedGenderAttributes = []
  leaveType.gender_attributes?.forEach((gender) => {
    if (!newValues.gender_attributes.includes(gender.gender)) {
      updatedGenderAttributes.push({ id: gender.id, _destroy: true })
    }
  })
  let oldGender = leaveType.gender_attributes.map((gender) => gender.gender)
  newValues.gender_attributes?.forEach((gender) => {
    if (!oldGender.includes(gender)) {
      updatedGenderAttributes.push({ gender: gender })
    }
  })
  return updatedGenderAttributes
}

export const mapDesignations = (designations) =>
  designations.map((designation) => ({
    id: designation.id,
    bindable_title: designation.bindable_title,
    value: designation.bindable_id,
    label: designation.bindable_title,
    short_name: designation.short_name
  }))

export const setReasonAttributes = (newValues, leaveType) => {
  let updatedReasonAttributes = []
  leaveType.reason_attributes?.forEach((reason) => {
    if (!newValues.reason_attributes.includes(reason.reason)) {
      updatedReasonAttributes.push({ id: reason.id, _destroy: true })
    }
  })
  let oldReason = leaveType.reason_attributes.map((reason) => reason.reason)
  newValues.reason_attributes?.forEach((reason) => {
    if (!oldReason.includes(reason)) {
      updatedReasonAttributes.push({ reason: reason })
    }
  })
  return updatedReasonAttributes
}

export const setEmploymentTypeAttributes = (newValues, rule) => {
  let updatedEmploymentTypeAttributes = []
  let oldEmploymentType = []
  rule.employment_types?.forEach((employment_type) => {
    oldEmploymentType.push(employment_type.employment_type)
    if (!newValues.employment_types.includes(employment_type.employment_type)) {
      updatedEmploymentTypeAttributes.push({ id: employment_type.id, _destroy: true })
    }
  })
  newValues.employment_types?.forEach((employment_type) => {
    if (!oldEmploymentType.includes(employment_type)) {
      updatedEmploymentTypeAttributes.push({ employment_type: employment_type })
    }
  })
  return updatedEmploymentTypeAttributes
}

export const announcmentPosters = [
  {
    name: 'Welcome',
    src: Welcome
  },
  {
    name: 'Promotion',
    src: Promotion
  },
  {
    name: 'Anniversary',
    src: Anniversay
  },
  {
    name: 'Birthday',
    src: Birthday
  }
]

export const activeSubscriptionTabs = [
  {
    value: '/application-settings/ha-ops/active-subscriptions',
    label: 'Remaining Users',
    href: '/application-settings/ha-ops/active-subscriptions'
  },
  {
    value: '/application-settings/ha-ops/active-subscriptions/availed-user',
    label: 'Availed User',
    href: '/application-settings/ha-ops/active-subscriptions/availed-user'
  }
]

export const totalLevels = (titles, selectedLevels) =>
  titles.reduce((levels, title) => {
    if (selectedLevels?.includes(title.id)) {
      levels.push(title.level)
    }
    return levels
  }, [])

const getValues = (levelRankValues) =>
  checkArrayPresence(levelRankValues) ? levelRankValues.map((levelRank) => levelRank.value ?? levelRank) : []

export const filteredTitles = (titles, amountSetting) => {
  const ranks = getValues(amountSetting.rank)
  const allLevels = totalLevels(titles, getValues(amountSetting.level))
  return titles.filter((title) => ranks.includes(title.title_rank?.id) && allLevels.includes(title.level))
}
